import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Login',
    meta: { pageTitle: "Zaloguj się", title: 'Zaloguj się' },
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard.vue'),
    children: [
      {
        path: 'settings',
        component: () => import(/* webpackChunkName: "ManageUsers" */ '../views/UserSettings.vue'),
        meta: { pageTitle: "Honesty Office - Ustawienia użytkownika", title: 'Ustawienia użytkownika' }
      },
      {
        path: 'users',
        component: () => import(/* webpackChunkName: "ManageUsers" */ '../views/Users.vue'),
        meta: { pageTitle: "Honesty Admin - Użytkownicy", title: 'Użytkownicy' },
      },
      { 
        path: 'users/:id', 
        component: () => import(/* webpackChunkName: "ManageUsers" */ '../views/UserDetails.vue'),
        meta: { pageTitle: "Honesty Admin - Szczegóły użytkownika", title: 'Szczegóły użytkownika' },
      },
      {
        path: 'deals',
        component: () => import(/* webpackChunkName: "ManageUsers" */ '../views/Deals.vue'),
        meta: { pageTitle: "Honesty Admin - Umowy", title: 'Umowy' },
      },
      {
        path: 'deal',
        component: () => import(/* webpackChunkName: "ManageUsers" */ '../components/DealView.vue'),
        meta: { pageTitle: "Honesty Admin - Podgląd umowy", title: 'Podgląd umowy' },
      },
      {
        path: 'document',
        component: () => import(/* webpackChunkName: "ManageUsers" */ '../components/InvoiceContractView.vue'),
        meta: { pageTitle: "Honesty Admin - Podgląd dokumentu", title: 'Podgląd dokumentu' },
      },
      {
        path: 'training',
        component: () => import(/* webpackChunkName: "ManageUsers" */ '../views/Training.vue'),
        meta: { pageTitle: "Honesty Office - Szkolenia", title: 'Szkolenia' }
      },
      {
        path: 'library',
        component: () => import(/* webpackChunkName: "ManageUsers" */ '../views/Library.vue'),
        meta: { pageTitle: "Honesty Office - Biblioteka", title: 'Biblioteka' }
      },
      {
        path: 'files',
        component: () => import(/* webpackChunkName: "ManageUsers" */ '../views/Files.vue'),
        meta: { pageTitle: "Honesty Admin - Pliki do pobrania", title: 'Pliki' },
      },
      {
        path: 'competitions',
        component: () => import(/* webpackChunkName: "ManageUsers" */ '../views/Competitions.vue'),
        meta: { pageTitle: "Honesty Admin - Konkursy", title: 'Konkursy' },
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

