import { createApp } from 'vue'
import FloatingVue from 'floating-vue';
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import VueDatePicker from '@vuepic/vue-datepicker';
import contenteditable from 'vue-contenteditable'

import App from './App.vue'
import router from './router'
import store from './store'

import './assets/tailwind.css'
import '@/styles/tooltip.css'
import '@vuepic/vue-datepicker/dist/main.css';

const app = createApp(App)

app.component('VueDatePicker', VueDatePicker).component('ContentEditable', contenteditable)
app.use(store).use(router).use(FloatingVue).use(autoAnimatePlugin).mount('#app')
