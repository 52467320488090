<template>
  <div id="app" class="h-screen w-screen bg-gray-50">
    <router-view />
    <Transition name="fade">
      <VersionAlert @closeAlert="closeAlert()" class="absolute left-1/2 transform -translate-x-1/2 top-6 z-50" v-if="versionAlert.show && !versionAlert.deploy"></VersionAlert>
    </Transition>
    <Transition name="fade-loader">
      <Loader v-if="!$store.state.authIsReady && this.$route.fullPath !== '/' || $store.state.globalLoader.show" :header="$store.state.globalLoader.head"  :subheader="$store.state.globalLoader.subheader"></Loader>
    </Transition>
    <Transition name="fade">
        <Notification v-if="$store.state.notification.show"></Notification>
    </Transition>
  </div>
  
</template>
<script>
  import Loader from '@/components/Loader.vue';
  import VersionAlert from '@/components/VersionAlert.vue';
  import Notification from '@/components/Notification.vue';
  export default {
    components: { VersionAlert, Loader, Notification },
    data()
    {
        return {
          versionAlert: {
            show: true,
            deploy: true
          }
        }
    },
    watch: {
      // Set meta tags
      $route(to) {
        this.setMeta(to.meta);
      },
    },
    methods: {
      setViewHeight() {
        let vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
      },
      closeAlert() {
        this.versionAlert.show = false
      },
      setMeta(meta) {
        document.title = meta.pageTitle;
        if(meta.title === undefined)
        {
          document.title = 'Honesty office';
        }
      }
    },
    async created() {
      if(process.env.VUE_APP_VERSION === 'SANDBOX_LOCAL') {
        this.$store.state.apiLink = "http://192.168.50.168:3000/api/sandbox"
        this.$store.state.apiKey = process.env.VUE_APP_APIKEY_SANDBOX
      } else if(process.env.VUE_APP_VERSION === 'SANDBOX') {
        this.$store.state.apiLink = "https://honestygr-bcknd.cmplapis.com/api/sandbox"
        this.$store.state.apiKey = process.env.VUE_APP_APIKEY_SANDBOX
      } else if(process.env.VUE_APP_VERSION === 'PRODUCTION_LOCAL') {
        this.$store.state.apiLink = "http://192.168.50.168:3000/api"
        this.$store.state.apiKey = process.env.VUE_APP_APIKEY_PRODUCTION
      } else if(process.env.VUE_APP_VERSION === 'PRODUCTION') {
        this.$store.state.apiLink = "https://honestygr-bcknd.cmplapis.com/api"
        this.$store.state.apiKey = process.env.VUE_APP_APIKEY_PRODUCTION
        this.deploy = true
      }
    },
    mounted() {
      this.setViewHeight()
      window.addEventListener('resize', () => {
        this.setViewHeight()
      })
    }
  }
</script>
<style>
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s ease;
}
.fade-enter-from,.fade-leave-to {
  opacity: 0;
}
.fade-loader-enter-active, .fade-loader-leave-active {
    transition: opacity 0.5s ease;
}
.fade-loader-enter-from,.fade-loader-leave-to {
  opacity: 0;
}
body
{
  overflow-x: hidden;
}
/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
  
/* Track */
::-webkit-scrollbar-track {
    background: rgb(209, 213, 219);
    border-radius: 5px;
}
  
/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(126, 131, 143);
    border-radius: 5px;
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@media (min-width: 640px) {
  /* width */
  ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
  }
    
  /* Track */
  ::-webkit-scrollbar-track {
      background: rgb(209, 213, 219);
      border-radius: 10px;
  }
    
  /* Handle */
  ::-webkit-scrollbar-thumb {
      background: rgb(126, 131, 143);
      border-radius: 10px;
  }
    
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
      background: #555;
  }
}

</style>

